import { PortableText } from '@portabletext/react'
import { SanityImageObject } from '@sanity/image-url/lib/types/types'
import cn from 'classnames'
import React from 'react'

import imageUrlFor from '../utils/imageUrlFor'
import Image, { SanityImage } from './Image'

export type SmallTextProps = {
  title: string
  text: any
}

type Props = {
  data: SmallTextProps
  className?: string
  style?: React.CSSProperties
}

const SmallText = (props: Props) => {
  return (
    <div className="mx-4 md:mx-8 py-52 text-center flex flex-col items-center">
      <div className="max-w-2xl">
        <h2 className="text-4xl mb-10">{props.data.title}</h2>
        <PortableText value={props.data.text} />
      </div>
    </div>
  )
}

export default SmallText
