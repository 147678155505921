import * as React from 'react'

import Accordion from './Accordion'
import CardSection from './CardSection'
import ContactListBlock from './ContactListBlock'
import Help from './Help'
import Hero from './Hero'
import ImageWithText from './ImageWithText'
import ListingPreview from './ListingsPreview'
import Newsletter from './Newsletter'
import Quote from './Quote'
import SmallHero from './SmallHero'
import SmallText from './SmallText'

type BlockType = 'imageWithText' | 'quote' | 'help' | 'newsletter'
type Props = {
  blocks: any[]
}

const RenderPageBuilder = (props: Props) => {
  return (
    <>
      {props.blocks.map((el, i) => {
        if (el._type === 'hero') return <Hero data={el} key={i} />
        else if (el._type === 'smallHero') return <SmallHero data={el} key={i} />
        else if (el._type === 'imageWithText') return <ImageWithText data={el} key={i} />
        else if (el._type === 'quote') return <Quote data={el} key={i} />
        else if (el._type === 'help') return <Help data={el} key={i} />
        else if (el._type === 'newsletter') return <Newsletter data={el} key={i} />
        else if (el._type === 'listingPreview') return <ListingPreview data={el} key={i} />
        else if (el._type === 'cardSection') return <CardSection data={el} key={i} />
        else if (el._type === 'smallText') return <SmallText data={el} key={i} />
        else if (el._type === 'contactListBlock') return <ContactListBlock data={el} key={i} />
        else if (el._type === 'accordionBlock') return <Accordion data={el} key={i} />
      })}
    </>
  )
}

export default RenderPageBuilder
